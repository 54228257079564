<template>
  <div class="confirm_wrap">
    <div class="confirm_img">
      <div>
        <van-image
          v-if="formData.positive"
          class="confirm_img_li"
          :src="formData.positive"
        />
        <van-image v-else class="confirm_img_li" :src="positiveLocal" />
        <div class="confirm_img_text">身份证正面</div>
      </div>
      <div>
        <van-image
          v-if="formData.other"
          class="confirm_img_li"
          :src="formData.other"
        />
        <van-image v-else class="confirm_img_li" :src="otherLocal" />
        <div class="confirm_img_text">身份证反面</div>
      </div>
    </div>
    <div class="confirm_private">
      上传的信息仅用于签约，不会泄露您的隐私，请放心使用
    </div>
    <div class="confirm_center">
      <div class="confirm_center_list">
        <div class="confirm_center_label">姓名:</div>
        <div class="confirm_center_val">
          {{ formData.name }}
        </div>
      </div>
      <div class="confirm_center_list">
        <div class="confirm_center_label">身份证号码:</div>
        <div class="confirm_center_val">
          {{ formData.card_code }}
        </div>
      </div>
      <div class="confirm_center_list">
        <div class="confirm_center_label">手机号码:</div>
        <div class="confirm_center_val">
          {{ formData.phone }}
        </div>
      </div>
      <div class="confirm_center_list">
        <div class="confirm_center_label">银行卡号:</div>
        <div class="confirm_center_val">
          {{ formData.bank_account }}
        </div>
      </div>
      <div class="confirm_center_list">
        <div class="confirm_center_label">开户行:</div>
        <div class="confirm_center_val">
          {{ formData.bank_title }}
        </div>
      </div>
      <div class="confirm_center_list">
        <div class="confirm_center_label">户籍地址:</div>
        <div class="confirm_center_val">
          {{ formData.address }}
        </div>
      </div>
    </div>
    <div class="confirm_button">
      <van-button type="primary" @click="toQianyue" v-if="isPass"
        >去签约</van-button
      >
      <van-button type="info" @click="toMyInfo">修改信息</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      positiveLocal: require("../assets/images/positive.png"),
      otherLocal: require("../assets/images/other.png"),
      formData: {
        other: "",
        positive: "",
        name: "",
        card_code: "",
        phone: "",
        bank_account: "",
        bank_title: "",
        address: "",
      },
      pid: "",
      isPass: false,
    };
  },
  mounted() {
    let pid = localStorage.getItem("pid");
    this.pid = pid;
    let val = this.$route.params;
    if (val.isPass) {
      this.isPass = true;
    }
    this.getPhotoInfo();
    // if (!this.pid) {
    //   this.$dialog
    //     .alert({
    //       message: "拒绝访问！",
    //       showConfirmButton: false,
    //     })
    //     .then(() => {});
    // }
  },
  methods: {
    //获取个人信息
    getPhotoInfo() {
      this.$axios
        .get(
          "/tag/info?table=person&table2=person_salary&fieldone=id&fieldtwo=person_id&id= " +
            this.pid +
            '&fieldasname={"name":"username","card_code":"usercard_code","phone":"phone","address":"address","pic":"pic","data_status":"data_statusb"}'
        )
        .then((res) => {
          if (res.data.success) {
            let returnData = res.data.data;
            this.formData.name = returnData.username;
            this.formData.card_code = returnData.usercard_code;
            this.formData.phone = returnData.phone;
            this.formData.bank_account = returnData.bank_account;
            this.formData.bank_title = returnData.bank_title;
            this.formData.address = returnData.address;
            this.formData.positive = returnData.pic.split(",")[0];
            this.formData.other = returnData.pic.split(",")[1];
            this.positiveFileList[0] = { url: this.formData.positive };
            this.otherFileList[0] = { url: this.formData.other };
            localStorage.setItem("realname", returnData.username);
            localStorage.setItem("card_code", returnData.usercard_code);
          } else {
            this.$toast({ message: res.data.msg });
          }
        })
        .catch(() => {});
    },
    toMyInfo() {
      this.$router.push({ name: "myInfo" });
    },
    toQianyue() {
      this.$toast({ message: "前往签约页面" });
      this.$axios
        .get("/person/getflowsUrl?pid=" + this.pid)
        .then((res) => {
          if (res.data.success) {
            window.location.href = res.data.data.url;
          } else {
            this.$toast({ message: res.data.msg });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
/* .confirm_wrap {
  position: relative;
} */

.confirm_private {
  color: red;
  font-size: 0.8rem;
  width: calc(100% - 2rem);
  margin: 0 auto;
  margin-bottom: 1rem;
}
.confirm_img {
  width: calc(100% - 2rem);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid #eee;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.confirm_img_li {
  width: 10rem;
  height: 8rem;
}
.confirm_img_text {
  text-align: center;
  line-height: 1.2rem;
}
.confirm_center {
  width: calc(100% - 2rem);
  margin: 0 auto;
  border: 1px solid #eee;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
}
.confirm_center_list {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  height: 2.4rem;
  line-height: 2.4rem;
  color: #646566;
  font-size: 1rem;
}
.confirm_center_label {
  text-align: left;
  width: 34%;
  padding-left: 0.5rem;
  box-sizing: border-box;
}
.confirm_center_val {
  text-align: left;
  width: 66%;
  overflow: hidden;
  text-overflow: ellipsis; /* 文本溢出时显示省略号 */
  white-space: nowrap; /* 文本单行显示不换行； */
}
</style>
<style>
.confirm_wrap .van-toast__text {
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
}

.confirm_center .van-field__label {
  width: 6.2em;
}
.confirm_center .van-cell {
  line-height: 2.4rem;
  font-size: 1rem;
}
.confirm_button {
  width: calc(100% - 1rem);
  margin: 0 auto;
  /* display: flex; */
  /* justify-content: space-around; */
}
.confirm_button .van-button {
  font-size: 1rem;
  height: 2rem;
  border-radius: 0.5rem;
  width: 100%;
}
</style>